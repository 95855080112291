import React, { useState } from "react";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

const ForecastCard = ({ forecastResults, selectedTarget, columns, keyMapping }) => {
  const [show, setShow] = useState(true);

  if (!forecastResults) return null;

  // Find the display label for the target, falling back to the selectedTarget if not found.
  const targetLabel =
    (columns.find((col) => col.key === selectedTarget) || {}).name ||
    selectedTarget;

  // Invert keyMapping so that we map from actual key to display label.
  const invertedMapping =
    keyMapping && Object.keys(keyMapping).length
      ? Object.fromEntries(
          Object.entries(keyMapping).map(([display, actual]) => [actual, display])
        )
      : {};

  return (
    <Box
      borderWidth="1px"
      borderColor="gray.800"
      borderRadius="md"
      boxShadow="md"
      p={4}
      bg="white"
      mb={4}
    >
      {/* Header with toggle button */}
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontWeight="bold">
          {`ผลการทำนาย (Forecast) ${targetLabel}:`}
        </Text>
        <IconButton
          icon={show ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => setShow(!show)}
          variant="ghost"
          size="sm"
          aria-label="Toggle Collapse"
        />
      </Flex>

      {/* Collapsible content */}
      <Collapse in={show}>
        <Table maxW="1200px" variant="striped" colorScheme="alphagray">
          <Thead bgColor="gray.200">
            <Tr>
              <Th>
                <Text fontSize={15}>ปีที่ทำนาย</Text>
              </Th>
              <Th>
                <Text fontSize={15}>ค่าเฉลี่ย (ค่าที่ทำนาย)</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {forecastResults.forecast_years.map((year, index) => (
              <Tr key={year}>
                <Td>{year}</Td>
                <Td>{parseFloat(forecastResults.forecast[index]).toFixed(2)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/* Optionally, display the forecast plot image */}
        <Box mt={4}>
          <Text mb={2} fontWeight="bold">
            กราฟการทำนาย:
          </Text>
          <img
            src={`data:image/png;base64,${forecastResults.plot_image_base64}`}
            alt="Forecast Plot"
            style={{ maxWidth: "100%" }}
          />
        </Box>

        {/* Impact Analysis Section */}
        <Box mt={4}>
          <Text mb={2} fontWeight="bold">
            ผลการวิเคราะห์ผลกระทบ (Impact Analysis):
          </Text>
          {forecastResults.impact_analysis &&
          forecastResults.impact_analysis.impacts ? (
            <Table
              maxW="1200px"
              borderColor="gray.800"
              borderWidth={1}
              variant="striped"
              colorScheme="gray"
              size="sm"
            >
              <Thead bgColor="blue.100">
                <Tr>
                  <Th>
                    <Text fontSize={15}>ตัวแปร</Text>
                  </Th>
                  <Th>
                    <Text fontSize={15}>Coefficient</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(forecastResults.impact_analysis.impacts).map(
                  ([predictor, coef]) => {
                    const displayLabel = invertedMapping[predictor] || predictor;
                    return (
                      <Tr key={predictor}>
                        <Td>{displayLabel}</Td>
                        <Td>{coef}</Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          ) : (
            <Text fontSize="sm">ไม่มีข้อมูลผลกระทบให้แสดง</Text>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ForecastCard;
