import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Collapse,
  Spinner,
} from "@chakra-ui/react";
import DataGrid from "react-data-grid";
import Papa from "papaparse";
import axios from "axios";
import "react-data-grid/lib/styles.css";
import ForecastCard from "./cards/ForecastCard";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { FaBrain, FaCalculator, FaTrashRestore, FaUpload } from "react-icons/fa";

const AnalyticModelChoosing = () => {
  // 1. CSV & DataGrid states
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);

  // 2. Target and model results states
  const [selectedTarget, setSelectedTarget] = useState("");
  const [predictionResults, setPredictionResults] = useState(null);
  const [forecastResults, setForecastResults] = useState(null);

  // 3. Header detection & mapping (if double-header CSV)
  const [headerNumber, setHeaderNumber] = useState(1);
  const [keyMapping, setKeyMapping] = useState({});

  // 4. "Baseline" aggregator for each predictor & target
  const [currentPredictorValues, setCurrentPredictorValues] = useState({});
  const [targetCurrentValue, setTargetCurrentValue] = useState(null);

  // 5. Forecast & testing config
  const [forecastSteps, setForecastSteps] = useState(5);
  const [forecastModel, setForecastModel] = useState("arima");
  const [predictionOpen, setPredictionOpen] = useState(true);

  // 6. Single-predictor adjustments
  const [selectedAdjustmentPercent, setSelectedAdjustmentPercent] = useState(10);
  const [selectedAdjustmentDirection, setSelectedAdjustmentDirection] =
    useState("decrease");
  const [selectedPredictorForAdjustment, setSelectedPredictorForAdjustment] =
    useState("");
  const [singlePredictorAdjustment, setSinglePredictorAdjustment] = useState(null);

  // 7. Loading states for API calls
  const [isPredictLoading, setIsPredictLoading] = useState(false);
  const [isForecastLoading, setIsForecastLoading] = useState(false);
  const [baselineYear, setBaselineYear] = useState(null);

  // 8. Refs & modals
  const fileInputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Target (prediction) modal
  const {
    isOpen: forecastIsOpen,
    onOpen: forecastOnOpen,
    onClose: forecastOnClose,
  } = useDisclosure(); // Forecast modal

  // Helper: check if a row is entirely non-numeric
  const isRowNonNumeric = (row) =>
    row.every((cell) => {
      const trimmed = cell.toString().trim();
      return trimmed === "" || isNaN(trimmed);
    });

  //
  // A. Handle CSV file upload & parse
  //
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Detect double header from first 2 rows
    Papa.parse(file, {
      preview: 2,
      header: false,
      skipEmptyLines: true,
      complete: (previewResults) => {
        let detectedHeaderNumber = 1;
        if (previewResults.data && previewResults.data.length >= 2) {
          const row0 = previewResults.data[0];
          const row1 = previewResults.data[1];
          if (isRowNonNumeric(row0) && isRowNonNumeric(row1)) {
            detectedHeaderNumber = 2;
            const mapping = {};
            row0.forEach((label, i) => {
              mapping[label] = row1[i];
            });
            setKeyMapping(mapping);
          }
        }
        setHeaderNumber(detectedHeaderNumber);
      },
      error: (error) => {
        console.error("Error parsing CSV preview:", error);
        setHeaderNumber(1);
      },
    });

    // Parse CSV with header
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data, meta } = results;
        if (!meta || !meta.fields) {
          console.error("No header fields found in CSV.");
          return;
        }
        const newColumns = meta.fields.map((field) => ({
          key: field,
          name: field,
          resizable: true,
          sortable: true,
        }));
        setColumns(newColumns);
        setRows(data);
        setFileUploaded(true);
      },
      error: (error) => {
        console.error("Error parsing CSV file:", error);
      },
    });
  };

  //
  // B. Clear everything
  //
  const handleClear = () => {
    setColumns([]);
    setRows([]);
    setFileUploaded(false);
    setSelectedTarget("");
    setPredictionResults(null);
    setForecastResults(null);
    setSinglePredictorAdjustment(null);
    setSelectedPredictorForAdjustment("");
    setKeyMapping({});
    setCurrentPredictorValues({});
    setTargetCurrentValue(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  //
  // C. Once target is selected & CSV loaded, find baseline by aggregating all rows for the LATEST year
  //
  useEffect(() => {
    if (!fileUploaded || !selectedTarget || rows.length === 0) return;
  
    const yearCol = columns.find(
      (col) => col.key.toLowerCase() === "year" || col.key.includes("ปี")
    );
  
    if (!yearCol) {
      // If no year column, use the first row as baseline
      const row = rows[0];
      if (row) {
        const baselinePredictors = {};
        Object.keys(row).forEach((k) => {
          baselinePredictors[k] = parseFloat(row[k]) || 0;
        });
        setCurrentPredictorValues(baselinePredictors);
        setTargetCurrentValue(baselinePredictors[selectedTarget] || null);
        setBaselineYear("N/A");
      }
      return;
    }
  
    // Determine the maximum year (baseline year)
    let maxYear = -Infinity;
    rows.forEach((r) => {
      const y = parseFloat(r[yearCol.key]);
      if (!isNaN(y) && y > maxYear) {
        maxYear = y;
      }
    });
    if (maxYear === -Infinity) return;
    setBaselineYear(maxYear);
  
    // Filter all rows that match the maximum year
    const latestYearRows = rows.filter((r) => {
      const y = parseFloat(r[yearCol.key]);
      return !isNaN(y) && y === maxYear;
    });
  
    // Sum up each predictor's values across those rows
    if (latestYearRows.length > 0) {
      const baselinePredictors = {};
      columns.forEach((col) => {
        baselinePredictors[col.key] = 0;
      });
      latestYearRows.forEach((r) => {
        columns.forEach((col) => {
          const val = parseFloat(r[col.key]);
          if (!isNaN(val)) {
            baselinePredictors[col.key] += val;
          }
        });
      });
      setCurrentPredictorValues(baselinePredictors);
      setTargetCurrentValue(baselinePredictors[selectedTarget] || null);
    }
  }, [selectedTarget, fileUploaded, rows, columns]);

  //
  // D. Helper message for the target modal
  //
  const predictionMessage = () => {
    if (!fileUploaded || rows.length === 0 || !selectedTarget) return "";
    const yearColumnKey = columns.find(
      (col) =>
        col.key.toLowerCase() === "year" ||
        col.key.toLowerCase().includes("ปี")
    )?.key;
    if (!yearColumnKey) return "";
    const yearValues = rows.map((row) => row[yearColumnKey]).filter(Boolean);
    if (yearValues.length === 0) return "";
    const numericYears = yearValues
      .map((y) => parseInt(y, 10))
      .filter((y) => !isNaN(y));
    if (numericYears.length === 0) return "";
    const maxYear = Math.max(...numericYears);
    const previousYears = Array.from(new Set(numericYears))
      .filter((year) => year !== maxYear)
      .sort((a, b) => a - b);
    const targetLabel =
      columns.find((col) => col.key === selectedTarget)?.name ||
      selectedTarget;
    return `ระบบจะทำการเปรียบเทียบค่าความคลาดเคลื่อน (mean squared error) ของ model ต่างๆ จากการทำนายผล <b>${targetLabel}</b> โดยเทียบข้อมูลจริงที่ได้จากปี <b>${maxYear}</b> กับข้อมูล (ของปี <b>${maxYear}</b>) ที่ได้จากการประมาณการจากปีก่อนหน้า (ปี <b>${previousYears.join(
      ", "
    )}</b>)`;
  };

  //
  // E. Trigger the prediction API call as soon as the user selects a target
  //
  const handleConfirmTarget = async () => {
    if (!selectedTarget || !fileInputRef.current.files[0]) {
      console.error("Missing selected target or file");
      return;
    }
    setIsPredictLoading(true);
    let targetToSend = selectedTarget;
    if (headerNumber === 2 && keyMapping[selectedTarget]) {
      targetToSend = keyMapping[selectedTarget];
    }
    const formData = new FormData();
    formData.append("file", fileInputRef.current.files[0]);
    formData.append("target", targetToSend);
    formData.append("header_number", headerNumber);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MOUTH_API}/predict`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setPredictionResults(response.data);
    } catch (error) {
      console.error("Error fetching predictions:", error);
    }
    setIsPredictLoading(false);
    // The modal stays open so the user can view the results.
  };

  // Automatically trigger prediction when the target changes and a file is uploaded.
  useEffect(() => {
    if (fileUploaded && selectedTarget) {
      handleConfirmTarget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTarget, fileUploaded]);

  //
  // F. Confirm forecast -> call /forecast (remains unchanged)
  //
  const handleConfirmForecast = async () => {
    if (!selectedTarget || !fileInputRef.current.files[0]) {
      console.error("Missing selected target or file");
      return;
    }
    setIsForecastLoading(true);
    let targetToSend = selectedTarget;
    if (headerNumber === 2 && keyMapping[selectedTarget]) {
      targetToSend = keyMapping[selectedTarget];
    }
    const formData = new FormData();
    formData.append("file", fileInputRef.current.files[0]);
    formData.append("target", targetToSend);
    formData.append("header_number", headerNumber);
    formData.append("forecast_steps", forecastSteps);
    formData.append("model", forecastModel);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MOUTH_API}/forecast`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setForecastResults(response.data);
      forecastOnClose();
    } catch (error) {
      console.error("Error fetching forecast:", error);
    }
    setIsForecastLoading(false);
  };

  //
  // G. Compute single‐predictor adjustment for the selected predictor
  //
  const handleComputeSinglePredictorAdjustment = () => {
    if (!forecastResults || !forecastResults.impact_analysis) {
      console.error("No impact analysis available from /forecast");
      return;
    }
    if (!targetCurrentValue) {
      console.error("No current target value found.");
      return;
    }
    if (!selectedPredictorForAdjustment) {
      console.error("No predictor selected.");
      return;
    }

    const desiredPercent = selectedAdjustmentPercent / 100;
    const sign = selectedAdjustmentDirection === "decrease" ? -1 : 1;
    const requiredTargetChange = targetCurrentValue * desiredPercent * sign;
    const impacts = forecastResults.impact_analysis.impacts;
    const coef = parseFloat(impacts[selectedPredictorForAdjustment]);
    if (isNaN(coef) || coef === 0) {
      setSinglePredictorAdjustment({
        predictor: selectedPredictorForAdjustment,
        coefficient: coef,
        rawAdjustment: null,
        newCount: null,
        percentAdjustment: null,
      });
      return;
    }
    const rawChange = requiredTargetChange / coef;
    const baseline = currentPredictorValues[selectedPredictorForAdjustment] || 0;
    const newCount = baseline + rawChange;
    let percentAdjustment = null;
    if (baseline !== 0) {
      percentAdjustment = (rawChange / baseline) * 100;
    }
    setSinglePredictorAdjustment({
      predictor: selectedPredictorForAdjustment,
      coefficient: coef,
      rawAdjustment: rawChange,
      newCount,
      percentAdjustment,
    });
  };

  //
  // H. Render the single predictor result (if any)
  //
  const renderSinglePredictorAdjustmentResult = () => {
    if (!singlePredictorAdjustment) return null;
  
    const { predictor, coefficient, rawAdjustment, newCount, percentAdjustment } =
      singlePredictorAdjustment;
  
    const rawAdjStr = rawAdjustment != null ? rawAdjustment.toFixed(2) : "N/A";
    const newCtStr = newCount != null ? newCount.toFixed(2) : "N/A";
    const coefStr = coefficient != null ? coefficient.toFixed(3) : "N/A";
    const pctAdjStr =
      percentAdjustment != null ? percentAdjustment.toFixed(2) + "%" : "N/A";
    const baselineValue =
      currentPredictorValues[predictor] !== undefined
        ? currentPredictorValues[predictor].toFixed(2)
        : "N/A";
    const baselineYrStr = baselineYear ? baselineYear : "N/A";
  
    return (
      <Box
        mt={4}
        p={4}
        borderWidth="1px"
        borderColor="gray.800"
        borderRadius="md"
        boxShadow="md"
      >
        <Text fontWeight="bold" mb={2}>
          Single-Predictor Adjustment for "{predictor}"
        </Text>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Predictor</Th>
              <Th>Baseline Value</Th>
              <Th>Baseline Year</Th>
              <Th>Coefficient</Th>
              <Th>Raw Adjustment</Th>
              <Th>New Count</Th>
              <Th>% Change (from baseline)</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{predictor}</Td>
              <Td>{baselineValue}</Td>
              <Td>{baselineYrStr}</Td>
              <Td>{coefStr}</Td>
              <Td>{rawAdjStr}</Td>
              <Td>{newCtStr}</Td>
              <Td>{pctAdjStr}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    );
  };    

  return (
    <Flex direction="row" p={4} gap={4} w="100%">
      <Box w="100%">
        {/* Upload & Clear Buttons */}
        <Flex gap={2} mb={4} justify="space-between">
          <Flex gap={2}>
            <Button
              leftIcon={<FaUpload />}
              variant="outline"
              colorScheme="teal"
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
              isDisabled={fileUploaded}
            >
              อัพโหลด CSV ไฟล์
            </Button>
            <Button
              leftIcon={<FaTrashRestore />}
              variant="outline"
              colorScheme="orange"
              onClick={handleClear}
              isDisabled={!fileUploaded}
            >
              ล้างค่า
            </Button>
          </Flex>
          <Flex gap={2}>
            <Button
              px={6}
              leftIcon={<FaCalculator />}
              variant="outline"
              colorScheme="purple"
              onClick={onOpen}
              isDisabled={!fileUploaded}
              width="full"
            >
              ทดสอบ model
            </Button>
            <Button
              leftIcon={<FaBrain />}
              variant="outline"
              colorScheme="green"
              onClick={forecastOnOpen}
              isDisabled={!fileUploaded}
              width="full"
            >
              ทำนายผล
            </Button>
          </Flex>
        </Flex>
        <input
          type="file"
          accept=".csv"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />

        {/* If forecast results exist, show forecast card and single-predictor UI */}
        {forecastResults && (
          <>
            <ForecastCard
              forecastResults={forecastResults}
              selectedTarget={selectedTarget}
              columns={columns}
              keyMapping={keyMapping}
            />
            <Box mt={4} p={4} borderWidth="1px" borderColor="gray.800" borderRadius="md" boxShadow="md">
              <Text mb={2} fontWeight="bold">
                Target ปัจจุบัน: {targetCurrentValue !== null ? targetCurrentValue : "N/A"}
              </Text>
              <Text mb={2}>เลือกเปอร์เซ็นต์ที่จะเปลี่ยนแปลง (1-20%) และทิศทาง:</Text>
              <Flex gap={4} align="center">
                <NumberInput
                  value={selectedAdjustmentPercent}
                  min={1}
                  max={20}
                  onChange={(valueString) => setSelectedAdjustmentPercent(parseInt(valueString, 10))}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <RadioGroup onChange={setSelectedAdjustmentDirection} value={selectedAdjustmentDirection}>
                  <Stack direction="row">
                    <Radio value="increase">เพิ่ม</Radio>
                    <Radio value="decrease">ลด</Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
              <Text mt={4} mb={2} fontWeight="bold">
                เลือก Predictor ที่ต้องการคำนวณ:
              </Text>
              <RadioGroup onChange={setSelectedPredictorForAdjustment} value={selectedPredictorForAdjustment}>
                <Stack direction="column">
                  {forecastResults.impact_analysis &&
                    Object.keys(forecastResults.impact_analysis.impacts).map((p) => (
                      <Radio key={p} value={p}>
                        {p}
                      </Radio>
                    ))}
                </Stack>
              </RadioGroup>
              <Button
                mt={4}
                colorScheme="blue"
                onClick={handleComputeSinglePredictorAdjustment}
                isDisabled={!selectedPredictorForAdjustment}
              >
                คำนวณ Single-Predictor Adjustment
              </Button>
            </Box>
          </>
        )}

        {/* Show the single predictor result if computed */}
        {renderSinglePredictorAdjustmentResult()}

        {/* DataGrid for CSV display */}
        <Box
          w="100%"
          borderWidth={1}
          borderRadius="md"
          p={4}
          overflow="auto"
          bg="white"
          height="600px"
          maxHeight="600px"
          mt={4}
        >
          {columns.length > 0 && rows.length > 0 ? (
            <DataGrid
              columns={columns}
              rows={rows}
              rowHeight={40}
              defaultColumnOptions={{ resizable: true }}
            />
          ) : (
            <Text>กรุณาอัพโหลด CSV ไฟล์</Text>
          )}
        </Box>
      </Box>

      {/* Modal for selecting target variable with embedded Prediction Results */}
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>เลือกตัวแปรเป้าหมาย</ModalHeader>
          <ModalBody>
            <Flex direction="row" gap={4}>
              {/* Left Column: Target Selection */}
              <Box flex="1">
                <Text mb={4}>กรุณาเลือก target variable:</Text>
                <RadioGroup onChange={setSelectedTarget} value={selectedTarget}>
                  <Stack direction="column">
                    {columns.map((col) => (
                      <Radio key={col.key} value={col.key}>
                        {col.name}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                {selectedTarget && (
                  <Box mt={4} fontSize="sm" color="gray.600" dangerouslySetInnerHTML={{ __html: predictionMessage() }} />
                )}
              </Box>

              {/* Right Column: Prediction Results or Spinner */}
              <Box flex="1" borderWidth="1px" borderColor="gray.800" borderRadius="md" p={4} boxShadow="md">
                {isPredictLoading ? (
                  <Flex justify="center" align="center" h="100%">
                    <Spinner size="xl" />
                  </Flex>
                ) : (
                  predictionResults &&
                  predictionResults.Model && (
                    <>
                      <Flex justify="space-between" alignItems="center" mb={2}>
                        <Text fontWeight="bold">
                          ผลการเปรียบเทียบ Error ของ Model สำหรับ {selectedTarget}
                        </Text>
                      </Flex>
                      <Collapse in={predictionOpen}>
                        {(() => {
                          const sortedResults = predictionResults.Model.map((model, index) => ({
                            model,
                            rmse: predictionResults.RMSE[index],
                            mae: predictionResults.MAE[index],
                          })).sort((a, b) => a.rmse - b.rmse);
                          return (
                            <Table
                              bgColor="white"
                              maxW="1200px"
                              borderColor="gray.800"
                              borderWidth={1}
                              variant="striped"
                            >
                              <Thead bgColor="teal.100">
                                <Tr>
                                  <Th>
                                    <Text fontSize={15}>Model</Text>
                                  </Th>
                                  <Th>
                                    <Text fontSize={15}>RMSE</Text>
                                  </Th>
                                  <Th>
                                    <Text fontSize={15}>MAE</Text>
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {sortedResults.map((result) => (
                                  <Tr key={result.model}>
                                    <Td>{result.model}</Td>
                                    <Td>{result.rmse.toFixed(4)}</Td>
                                    <Td>{result.mae.toFixed(4)}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          );
                        })()}
                      </Collapse>
                    </>
                  )
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
          {!isPredictLoading && <Button variant="outline" px={4} colorScheme="teal" onClick={onClose}>
               {predictionResults ? "ปิด":"ยกเลิก"}
            </Button>}
            {isPredictLoading && <Text>กำลังประมวลผล...</Text>}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for forecast parameters (unchanged) */}
      <Modal isOpen={forecastIsOpen} onClose={forecastOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>เลือกตัวแปรและตั้งค่า forecast</ModalHeader>
          <ModalBody>
            <Text mb={4}>กรุณาเลือก target variable:</Text>
            <RadioGroup onChange={setSelectedTarget} value={selectedTarget}>
              <Stack direction="column">
                {columns.map((col) => (
                  <Radio key={col.key} value={col.key}>
                    {col.name}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            <Box mt={4}>
              <Text mb={2}>จำนวนปีที่ทำนาย (forecast steps):</Text>
              <NumberInput
                value={forecastSteps}
                min={1}
                onChange={(valueString) => setForecastSteps(parseInt(valueString, 10))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
            <Box mt={4}>
              <Text mb={2}>เลือกโมเดลที่ใช้สำหรับการทำนาย:</Text>
              <RadioGroup onChange={setForecastModel} value={forecastModel}>
                <Stack direction="column">
                  <Radio value="arma">ARMA</Radio>
                  <Radio value="arima">ARIMA</Radio>
                  <Radio value="arimax">ARIMAX</Radio>
                  <Radio value="svr">SVR</Radio>
                  <Radio value="randomforest">RandomForestRegressor</Radio>
                  <Radio value="gradientboosting">GradientBoostingRegressor</Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={forecastOnClose}>
              ยกเลิก
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmForecast}
              isDisabled={!selectedTarget}
              isLoading={isForecastLoading}
            >
              ยืนยัน
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AnalyticModelChoosing;
