import React, { useEffect, useState } from 'react';
import { Select, Flex, Text } from '@chakra-ui/react';

const TimeRangeWidget = ({resetTime, sHour, sMinute, eHour, eMinute, setStartTime, setEndTime}) => {
  const [startHour, setStartHour] = useState();
  const [startMinute, setStartMinute] = useState();
  const [endHour, setEndHour] = useState();
  const [endMinute, setEndMinute] = useState();

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 / 5 }, (_, i) => (i * 5).toString().padStart(2, '0'));

  useEffect(()=>{
    setStartHour(sHour)
    setStartMinute(sMinute)
    setEndHour(eHour)
    setEndMinute(eMinute)
  }, [])

  useEffect(()=>{
    if(resetTime){
      setStartHour("")
      setStartMinute("")
      setEndHour("")
      setEndMinute("")
    }
  }, [resetTime])

  useEffect(()=>{
    if(startHour && startMinute){
        setStartTime(`${startHour}:${startMinute}`)
    }
    else{
        setStartTime('')
    }
  }, [startHour, startMinute])

  useEffect(()=>{
    if(endHour && endMinute){
        setEndTime(`${endHour}:${endMinute}`)
    }
    else{
        setEndTime('')
    }
  }, [endHour, endMinute])

  return (
    <Flex justify={"flex-start"} align="center">
      <Select
        bgColor={"white"}
        w={"70px"}
        value={startHour}
        onChange={(e) => setStartHour(e.target.value)}
        marginRight="2"
      >
        <option value="">-</option>
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </Select>
      <Text>:</Text>
      <Select
        bgColor={"white"}
        w={"70px"}
        value={startMinute}
        onChange={(e) => setStartMinute(e.target.value)}
        marginLeft="2"
      >
        <option value="">-</option>
        {minutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </Select>

      <Text fontSize={12} mx="2"> ถึง </Text>
      <Select
        bgColor={"white"}
        w={"70px"}
        value={endHour}
        onChange={(e) => setEndHour(e.target.value)}
        marginRight="2"
      >
        <option value="">-</option>
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </Select>
      <Text>:</Text>
      <Select
        bgColor={"white"}
        w={"70px"}
        value={endMinute}
        onChange={(e) => setEndMinute(e.target.value)}
        marginLeft="2"
      >
        <option value="">-</option>
        {minutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default TimeRangeWidget;
