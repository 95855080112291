//const API_BASE_URL = "https://cavity-server.vercel.app";
//const API_BASE_URL = "https://cavity-server.vercel.app";
const API_BASE_URL = "https://webapi.dent.lingosoft.co";

//const API_BASE_URL = "http://localhost:3000";
//const API_BASE_URL = "http://165.232.166.19:3000";

module.exports = {
	API_BASE_URL,
};
