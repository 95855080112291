import React from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
} from '@chakra-ui/react';
import AnalyticTemplateComposition from './AnalyticTemplateCoposition';
import AnalyticModelChoosing from './AnalyticModelChoosing';

const Analytics = () => {
  // Optional: Custom styles for the selected tab for a more vibrant look.
  const selectedTabStyle = {
    color: useColorModeValue('white', 'white'),
    bg: useColorModeValue('blue.500', 'blue.200'),
  };

  return (
    <Box w={"98%"} bgColor={useColorModeValue("white", "gray.800")} mx="auto" mt={5} p={1} borderRadius="md" boxShadow="md">
      <Tabs mt={2} mx={2} variant="soft-rounded" colorScheme="blue">
        <TabList mb={4}>
          <Tab _selected={selectedTabStyle}>ออกแบบไฟล์สถิติ (CSV)</Tab>
          <Tab _selected={selectedTabStyle}>แปลผล/ทำนายผล</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <AnalyticTemplateComposition />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <AnalyticModelChoosing />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Analytics;
