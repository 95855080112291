// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-item {
    display: inline-block;
    margin-right: 5px;
  }
  
  .page-link {
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    background-color: #ecf4ff;
    border-radius: 5px;
    color: rgb(19, 19, 19);
    text-decoration: none;
    cursor: pointer;
  }
  
  .page-link:hover {
    background-color: #fafaaa;
  }

  .active-link {
    color:black;
    background-color: #fafaaa; /* Set your desired background color */
  }
  
  .active {
    color: #fafaaa;
  }

  .break-item {
    /* Add any custom styles for break elements here if needed */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .previous {
    border-right: solid 1px #808e9b;
    padding: 8px 12px;
    font-size: 14px;
    color: #808e9b;
    cursor: pointer;
  }
  
  .next {
    border-left: solid 1px #808e9b;
    padding: 8px 12px;
    font-size: 14px;
    color: #808e9b;
    cursor: pointer;
  }

  ul li::before {
    color: black;
    margin-left: 2px;
    margin-right: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/css/pagination.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,yBAAyB,EAAE,sCAAsC;EACnE;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,4DAA4D;EAC9D;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,+BAA+B;IAC/B,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,8BAA8B;IAC9B,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;EACnB","sourcesContent":[".page-item {\n    display: inline-block;\n    margin-right: 5px;\n  }\n  \n  .page-link {\n    padding-left: 10px;\n    padding-top: 5px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    background-color: #ecf4ff;\n    border-radius: 5px;\n    color: rgb(19, 19, 19);\n    text-decoration: none;\n    cursor: pointer;\n  }\n  \n  .page-link:hover {\n    background-color: #fafaaa;\n  }\n\n  .active-link {\n    color:black;\n    background-color: #fafaaa; /* Set your desired background color */\n  }\n  \n  .active {\n    color: #fafaaa;\n  }\n\n  .break-item {\n    /* Add any custom styles for break elements here if needed */\n  }\n  \n  .pagination {\n    display: flex;\n    justify-content: center;\n    list-style-type: none;\n    padding: 0;\n    margin-top: 20px;\n  }\n  \n  .previous {\n    border-right: solid 1px #808e9b;\n    padding: 8px 12px;\n    font-size: 14px;\n    color: #808e9b;\n    cursor: pointer;\n  }\n  \n  .next {\n    border-left: solid 1px #808e9b;\n    padding: 8px 12px;\n    font-size: 14px;\n    color: #808e9b;\n    cursor: pointer;\n  }\n\n  ul li::before {\n    color: black;\n    margin-left: 2px;\n    margin-right: 2px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
