import React from "react";
import { Container, Flex, useColorModeValue } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import ImageUploadComponent from "../components/ImageUploadComponent";

const YoloCariesDetection = () => {
    return (
        <>
            <Header />
            <Navbar />
            <Container
                bgColor={useColorModeValue("gray.200", "blue.900")}
                maxW="full"
                mt={0}
                centerContent
                overflow="hidden"
                py={10}
            >
                <Flex direction="column" align="center" p={4}>
                    <ImageUploadComponent apiUrl={`${process.env.REACT_APP_MOUTH_API}/detect_with_output_img/carries?threshold=0.3`} title={"ตรวจจับฟันผุจากภาพ"} />
                </Flex>
            </Container>
        </>

    );
};

export default YoloCariesDetection;
