import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme, { globalStyles } from "./theme/styles";

import Home from "./page/Home";
import Contact from "./page/Contact";
import About from "./page/About";
import NotFound from "./page/NotFound";

import Login from "./page/Login";
import Register from "./page/Register";
import ForgotPassword from "./page/ForgotPassword";
import ResetPassword from "./page/ResetPassword";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";

import Dashboard from "./components/screen/Dashboard";
import Diagnosis from "./components/screen/Diagnosis";
import Report from "./components/screen/Report";
import Usermanagement from "./components/screen/Usermanagement";
import Profile from "./components/screen/Profile";
import Undercare from "./components/screen/Undercare";
import UserListTable from "./components/screen/usermanagement/UserListTable";

import '@fontsource/kanit'; // Defaults to weight 400
import MouthDetection from "./page/MouthDetection";
import GumDetection from "./page/GumDetection";
import YoloCariesDetection from "./page/YoloCariesDetection";
import FissuredTongue from "./page/FissuredTongue";
import Calculus from "./page/Calculus";
import Analytics from "./components/screen/Analytics";
import ChatList from "./components/screen/ChatList";
import Oral from "./page/Oral";

function App() {
	return (
		<ChakraProvider theme={theme}>
			<Router>
				<AuthProvider>
					<Routes>
						<Route path="*" element={<NotFound />} />
						<Route path="/" element={<Home />} />
						<Route path="/contact" element={<Contact />}></Route>
						<Route path="/mouth_tracking" element={<MouthDetection />}></Route>
						<Route path="/gum_detection" element={<GumDetection />}></Route>
						<Route path="/caries_detection" element={<YoloCariesDetection />}></Route>
						<Route path="/fissured_tongue" element={<FissuredTongue />}></Route>
						<Route path="/calculus" element={<Calculus />}></Route>
						<Route path="/oral" element={<Oral />}></Route>
						<Route path="/about" element={<About />}></Route>

						<Route path="/login" element={<Login />} />
						<Route path="/loginadmin" element={<Login loginRole={"admin"} />} />
						<Route path="/register" element={<Register />} />
						<Route path="/forgotpassword" element={<ForgotPassword />} />
						<Route path="/setpassword/:id" element={<ResetPassword />} />
						<Route element={<PrivateRoute />}>
							<Route path="/analytics" element={<Analytics />} />
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/chatlist" element={<ChatList />} />
							<Route path="/diagnosis" element={<Diagnosis />} />
							<Route path="/diagnosis/:id" element={<Profile />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/report" element={<Report />} />
							<Route path="/usermanagement" element={<Usermanagement />} />
							<Route path="/undercare" element={<Undercare key={"undercare"} />} />
							<Route path="/prison" element={<Undercare key={"prison"} isPrison={1} />} />
							<Route path="/undercare/:staff_id/:name/:email" element={<Undercare key={"undercare_with_staff"} />} />
							<Route path="/userlist" element={<UserListTable />} />
						</Route>
					</Routes>
				</AuthProvider>
			</Router>
		</ChakraProvider>
	);
}

export default App;
