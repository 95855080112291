// DateSlider.js
import React, { useState, useRef, useEffect } from 'react';
import { Flex, Box, Text, Button, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, useDisclosure, Icon } from '@chakra-ui/react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import { format } from 'date-fns';
import { th } from 'date-fns/locale/th';
import moment from 'moment';
import { FaCalendarAlt } from 'react-icons/fa';

const DatePickerLabel = ({initDateStr, setSelectedDay}) => {
  const [selectedDate, setSelectedDate] = useState();
  const {isOpen, onOpen, onClose} = useDisclosure()

  useEffect(()=>{
    if(initDateStr){
      setSelectedDate(moment(initDateStr).format("YYYY-MM-DD"))
    }
    else{
      setSelectedDate(null)
    }
  },[initDateStr])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDay(moment(date).format("YYYY-MM-DD"))
    onClose();
  };

  return (
    <Box>
      <Flex mb="4">
        <Popover isLazy placement="top" onOpen={onOpen}
        onClose={onClose} isOpen={isOpen}>
          <PopoverTrigger>
            <Flex align={"center"}>
            <Button bgColor='white' onClick={()=>{onOpen()}} mx="2">
                <Text fontWeight={100} color="black">{selectedDate ? format(selectedDate, 'EEEE dd MMMM yyyy', { locale: th }): "กรุณาเลือกวัน"}</Text>
            </Button>
            <Icon as={FaCalendarAlt} color={"blueviolet"} cursor={"pointer"} w={4} h={4}/>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader align="center">เลือกวัน</PopoverHeader>
            <PopoverBody>
              <Calendar
                onChange={handleDateChange}
                value={selectedDate}
              />
              <Flex justify={"center"} width="100%">
              <Button variant={"ghost"} size={"sm"} my={2} onClick={()=>{onClose()}}>ยกเลิก</Button>
              </Flex>
            </PopoverBody>
            
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  );
};

export default DatePickerLabel;
