import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  VStack,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Flex,
  Text,
  List,
  ListItem,
} from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import DataGrid from "react-data-grid";
import Swal from "sweetalert2";
import { Reorder } from "framer-motion";
import "react-data-grid/lib/styles.css";

// Default columns configuration
const DEFAULT_COLUMNS = [
  { key: "gender", name: "เพศ", resizable: true, sortable: true },
  {
    key: "permanent_teeth",
    name: "จำนวนฟันแท้",
    resizable: true,
    sortable: true,
  },
  {
    key: "dental_caries",
    name: "จำนวนฟันผุ",
    resizable: true,
    sortable: true,
  },
  {
    key: "previous_extractions",
    name: "จำนวนฟันที่ถอนไปแล้ว",
    resizable: true,
    sortable: true,
  },
  {
    key: "teeth_filled",
    name: "จำนวนฟันที่อุด",
    resizable: true,
    sortable: true,
  },
  {
    key: "brushing_frequency",
    name: "ความถี่ในการแปรงฟัน",
    resizable: true,
    sortable: true,
  },
  {
    key: "fluoride_toothpaste",
    name: "ใช้ยาสีฟันที่มีฟลูออไรด์หรือไม่",
    resizable: true,
    sortable: true,
  },
  {
    key: "sweet_snacks_frequency",
    name: "ความถี่ในการรับประทานขนมหวาน",
    resizable: true,
    sortable: true,
  },
  {
    key: "dental_visits",
    name: "การเข้าพบทันตแพทย์",
    resizable: true,
    sortable: true,
  },
  {
    key: "year",
    name: "ปีที่บันทึกข้อมูล",
    resizable: true,
    sortable: true,
  },
];

const LOCAL_STORAGE_KEY = "analyticTemplateCompositionColumns";

const AnalyticTemplateComposition = () => {
  // Load columns from localStorage if available, otherwise use defaults
  const [columns, setColumns] = useState(() => {
    const storedColumns = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedColumns ? JSON.parse(storedColumns) : DEFAULT_COLUMNS;
  });
  const [newColumn, setNewColumn] = useState({ name: "", key: "" });
  const [editColumn, setEditColumn] = useState(null);

  // Disclosures for Add, Edit, and Reset Confirmation modals
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();

  // Save columns to localStorage whenever they change (including order changes)
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(columns));
  }, [columns]);

  // Confirm Reset: set columns to default
  const handleConfirmReset = () => {
    setColumns(DEFAULT_COLUMNS);
    onResetClose();
  };

  // Add Column
  // Add Column
  const handleAddColumn = () => {
    // Regex to match one or more lowercase letters or numbers,
    // optionally connected by underscores (e.g., "column1", "column_2", "a1_b2").
    const keyRegex = /^[a-z0-9]+(?:_[a-z0-9]+)*$/;

    // Check if both name and key are provided
    if (!newColumn.name || !newColumn.key) {
      Swal.fire({
        icon: "warning",
        title: "กรุณากรอกข้อมูลให้ครบ",
        text: "กรุณากรอกชื่อ Column และ Key",
      });
      return;
    }

    // Validate the key format
    if (!keyRegex.test(newColumn.key)) {
      Swal.fire({
        icon: "warning",
        title: "รูปแบบ Key ไม่ถูกต้อง",
        text: "Key ต้องเป็นตัวอักษรภาษาอังกฤษตัวพิมพ์เล็ก, ตัวเลข หรือ _ เท่านั้น",
      });
      return;
    }

    // If validations pass, add the new column
    setColumns([...columns, { ...newColumn, resizable: true, sortable: true }]);
    setNewColumn({ name: "", key: "" });
    onAddClose();
  };

  // Edit Column
  // Edit Column
  const handleEditColumn = () => {
    // Regex to match one or more lowercase letters or numbers,
    // optionally connected by underscores (e.g., "column1", "column_2", "a1_b2").
    const keyRegex = /^[a-z0-9]+(?:_[a-z0-9]+)*$/;

    // Check if both name and key are provided
    if (!editColumn.name || !editColumn.key) {
      Swal.fire({
        icon: "warning",
        title: "กรุณากรอกข้อมูลให้ครบ",
        text: "กรุณากรอกชื่อ Column และ Key",
      });
      return;
    }

    // Validate the key format
    if (!keyRegex.test(editColumn.key)) {
      Swal.fire({
        icon: "warning",
        title: "รูปแบบ Key ไม่ถูกต้อง",
        text: "Key ต้องเป็นตัวอักษรภาษาอังกฤษตัวพิมพ์เล็ก, ตัวเลข หรือ _ เท่านั้น",
      });
      return;
    }

    // If validations pass, update the column
    setColumns(
      columns.map((col) =>
        col.key === editColumn.originalKey
          ? { ...editColumn, resizable: true, sortable: true }
          : col
      )
    );
    setEditColumn(null);
    onEditClose();
  };

  // Open Edit Modal
  const handleOpenEdit = (col) => {
    setEditColumn({ ...col, originalKey: col.key });
    onEditOpen();
  };

  // Delete Column with Confirmation
  const handleDeleteColumn = (index) => {
    Swal.fire({
      title: "ยืนยันการลบ?",
      text: `คุณกําลังลบคอลัมน์ "${columns[index].name}" จากตาราง คุณแน่ใจหรือไม่ว่าต้องการลบคอลัมน์ ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        setColumns(columns.filter((_, i) => i !== index));
      }
    });
  };

  // Move Columns via Buttons (optional, can be used along with drag functionality)
  const handleMoveColumn = (index, direction) => {
    const newColumns = [...columns];
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    if (targetIndex >= 0 && targetIndex < columns.length) {
      [newColumns[index], newColumns[targetIndex]] = [
        newColumns[targetIndex],
        newColumns[index],
      ];
      setColumns(newColumns);
    }
  };

  // Download CSV
  const downloadCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
    const headers = columns.map((col) => col.name).join(",") + "\n";
    const keys = columns.map((col) => col.key).join(",") + "\n";
    csvContent += headers + keys;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Create key row and dummy rows for DataGrid preview
  const keyRow = columns.reduce((acc, col) => ({ ...acc, [col.key]: col.key }), {});
  const dummyRows = Array.from({ length: 7 }, () =>
    columns.reduce((acc, col) => ({ ...acc, [col.key]: "" }), {})
  );

  return (
    <Flex p={1} gap={4}>
      {/* DataGrid Section */}
      <Box
        flex="2"
        borderWidth={1}
        borderRadius="md"
        p={4}
        overflowX="scroll"
        overflowY="scroll"
        bg="white"
      >
        <style>
          {`
            .rdg-header-row {
              background-color: #f5f5f5 !important;
              color: #333 !important;
              border-bottom: 2px solid #ddd !important;
            }
            .rdg-row {
              background-color: white !important;
              color: black !important;
            }
            .rdg-cell {
              border: 1px solid #ddd !important;
            }
            ::-webkit-scrollbar {
              width: 8px;
              height: 12px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #888;
              border-radius: 4px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background-color: #555;
            }
          `}
        </style>
        <Text fontWeight={"bold"} color={"teal.600"} mb={1}>
          ตัวอย่างไฟล์ CSV
        </Text>
        <DataGrid
          columns={columns}
          rows={[keyRow, ...dummyRows]}
          rowHeight={40}
          defaultColumnOptions={{ resizable: true }}
          style={{ height: "400px", backgroundColor: "white", color: "black" }}
        />
      </Box>

      {/* Column Management Section */}
      <Box flex="1" borderWidth={1} p={4} borderRadius="md" bg="gray.100" w="full">
        <HStack spacing={2} mb={4} w="full">
          <Button variant={"outline"} colorScheme="teal" onClick={onAddOpen} flex="1">
            + เพิ่ม column
          </Button>
          <Button
            variant={"outline"}
            leftIcon={<DownloadIcon />}
            colorScheme="blue"
            onClick={downloadCSV}
            flex="1"
          >
            ดาวน์โหลด CSV
          </Button>
          <Button variant={"outline"} colorScheme="orange" onClick={onResetOpen} flex="1">
            รีเซ็ต
          </Button>
        </HStack>

        <Box w="full">
          {/* Drag-reorderable list */}
          <Reorder.Group
            axis="y"
            values={columns}
            onReorder={setColumns}
            style={{ width: "100%" }}
          >
            {columns.map((col, index) => (
              <Reorder.Item as="li" key={col.key} value={col} style={{ listStyleType: "none" }}>
                <HStack
                  bgColor={"gray.50"}
                  w="full"
                  justifyContent="space-between"
                  borderBottomWidth={1}
                  borderBottomColor="gray.800"
                  p={2}
                >
                  <Box flex="1">
                    <Box fontWeight="bold">{col.name}</Box>
                    <Box fontSize="sm" color="gray.500">
                      {col.key}
                    </Box>
                  </Box>
                  <HStack spacing={1}>
                    <IconButton
                      size={"xs"}
                      variant={"ghost"}
                      icon={<ArrowUpIcon />}
                      colorScheme="gray"
                      onClick={() => handleMoveColumn(index, "up")}
                      isDisabled={index === 0}
                    />
                    <IconButton
                      size={"xs"}
                      variant={"ghost"}
                      icon={<ArrowDownIcon />}
                      colorScheme="gray"
                      onClick={() => handleMoveColumn(index, "down")}
                      isDisabled={index === columns.length - 1}
                    />
                    <IconButton
                      size={"xs"}
                      variant={"ghost"}
                      icon={<EditIcon />}
                      colorScheme="blue"
                      onClick={() => handleOpenEdit(col)}
                    />
                    <IconButton
                      size={"xs"}
                      variant={"ghost"}
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => handleDeleteColumn(index)}
                    />
                  </HStack>
                </HStack>
              </Reorder.Item>
            ))}
          </Reorder.Group>
        </Box>
      </Box>

      {/* Add Column Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>เพิ่ม column</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="ชื่อ Column"
                value={newColumn.name}
                onChange={(e) =>
                  setNewColumn({ ...newColumn, name: e.target.value })
                }
              />
              <Input
                placeholder="Key"
                value={newColumn.key}
                onChange={(e) =>
                  setNewColumn({ ...newColumn, key: e.target.value })
                }
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} colorScheme="blue" onClick={handleAddColumn}>
              เพิ่ม
            </Button>
            <Button variant={"outline"} colorScheme="gray" onClick={onAddClose}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Column Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>แก้ไข column</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="ชื่อ Column"
                value={editColumn?.name || ""}
                onChange={(e) =>
                  setEditColumn({ ...editColumn, name: e.target.value })
                }
              />
              <Input
                placeholder="Key"
                value={editColumn?.key || ""}
                onChange={(e) =>
                  setEditColumn({ ...editColumn, key: e.target.value })
                }
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button mr={2} colorScheme="blue" onClick={handleEditColumn}>
              บันทึก
            </Button>
            <Button variant={"outline"} colorScheme="gray" onClick={onEditClose}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Reset Confirmation Modal */}
      <Modal isOpen={isResetOpen} onClose={onResetClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>รีเซ็ตคอลัมน์</ModalHeader>
          <ModalBody>
            <Text mb={2}>คอลัมน์เริ่มต้นที่จะถูกตั้งค่าใหม่:</Text>
            <List spacing={1}>
              {DEFAULT_COLUMNS.map((col) => (
                <ListItem key={col.key}>
                  {col.name} <Text as="span" fontSize="sm" color="gray.500">({col.key})</Text>
                </ListItem>
              ))}
            </List>
            <Text mt={4} fontWeight="semibold">
              คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตคอลัมน์ทั้งหมดให้เป็นค่าเริ่มต้น?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant={"outline"} colorScheme="orange" mr={3} onClick={handleConfirmReset}>
              รีเซ็ต
            </Button>
            <Button onClick={onResetClose}>ยกเลิก</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AnalyticTemplateComposition;
