import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

window.addEventListener("error", (event) => {
	if (event.message && event.message.includes("ResizeObserver loop completed")) {
	  event.stopImmediatePropagation();
	  event.preventDefault();
	  return true;
	}
  });
  
  window.addEventListener("unhandledrejection", (event) => {
	if (
	  event.reason &&
	  event.reason.message &&
	  event.reason.message.includes("ResizeObserver loop completed")
	) {
	  event.preventDefault();
	}
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
