// Profile.js
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  Heading,
  Divider,
  Grid,
  GridItem,
  Icon,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Switch,
  Flex,
  Image,
  HStack,
  Button,
  Badge,
  Textarea,
  useToast,
  Center,
  Spacer,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { DeleteIcon } from "@chakra-ui/icons";
import { MdZoomOutMap } from "react-icons/md";
import {
  FaIdCard,
  FaBirthdayCake,
  FaPhone,
  FaMapMarker,
  FaHospital,
  FaClipboard,
  FaComments,
} from "react-icons/fa";
import { FiSend, FiImage } from "react-icons/fi";
import ImageUploadModal from "../modal/ImageUploadModal";
import { useAuth } from "../../hooks/AuthProvider";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import {
  converterToThaiDate,
  converterToAge,
  converterToThaiDateAndTime,
} from "../../util/dateUtils";

/* ----------------------------------------------------------------------------
   ChatModalContent Component
   This component implements a chat interface that supports text and image
   messages. It includes an input field, send button, image upload button,
   and a full-screen image preview (when an image message is clicked).
------------------------------------------------------------------------------- */
const ChatModalContent = () => {
  const [messages, setMessages] = useState([]); // chat messages array
  const [inputValue, setInputValue] = useState("");
  const fileInputRef = useRef(null);
  const [modalImage, setModalImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  // Send a text message
  const handleSendMessage = () => {
    if (inputValue.trim() === "") return;
    const newMessage = {
      position: "right", // your messages on the right side
      type: "text",
      text: inputValue,
      date: new Date(),
    };
    setMessages([...messages, newMessage]);
    setInputValue("");
  };

  // Handle image file upload and create an image message
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImageMessage = {
        position: "right",
        type: "image",
        image: URL.createObjectURL(file),
        date: new Date(),
      };
      setMessages([...messages, newImageMessage]);
    }
  };

  // Trigger the hidden file input
  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  // When an image is clicked, show a full-size preview
  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setIsImageModalOpen(true);
  };

  return (
    <Box
      backgroundColor="blue.50"
      borderColor="black"
      borderWidth="1px"
      flex="1"
      display="flex"
      flexDirection="column"
      height="70vh"
    >
      {/* Chat Header */}
      <Box
        backgroundColor="white"
        borderBottom="1px solid #e2e8f0"
        pb={2}
        mb={4}
        px={4}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="2xl" fontWeight="bold">
            Chat
          </Text>
          <Button size="sm" variant="outline" colorScheme="teal">
            สร้างลิงค์ประชุม
          </Button>
        </Flex>
      </Box>

      {/* Messages Display */}
      <Box flex="1" overflowY="auto" mb={4} px={4}>
        <VStack spacing={4} align="stretch">
          {messages.map((msg, index) => (
            <Box
              key={index}
              alignSelf={msg.position === "right" ? "flex-end" : "flex-start"}
              maxW="70%"
              bg={msg.position === "right" ? "blue.100" : "gray.100"}
              p={3}
              borderRadius="md"
            >
              {msg.type === "text" ? (
                <Text>{msg.text}</Text>
              ) : (
                <Image
                  src={msg.image}
                  alt="Sent image"
                  maxH="300px"
                  objectFit="cover"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handleImageClick(msg.image)}
                />
              )}
              <Text fontSize="xs" color="gray.500" textAlign="right">
                {msg.date.toLocaleTimeString()}
              </Text>
            </Box>
          ))}
        </VStack>
      </Box>

      {/* Message Input */}
      <Flex
        bgColor="gray.200"
        my={1}
        mx={1}
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSendMessage();
        }}
        align="center"
      >
        <Input
          bgColor="white"
          _placeholder={{ color: "gray.300" }}
          placeholder="พิมพ์ข้อความ..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          mr={2}
        />
        <Button leftIcon={<FiSend />} type="submit" colorScheme="blue" mr={2}>
          ส่ง
        </Button>
        <Button
          leftIcon={<FiImage />}
          variant="outline"
          onClick={handleUploadClick}
          colorScheme="green"
        >
          ภาพ
        </Button>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </Flex>

      {/* Full-size Image Modal */}
      {isImageModalOpen && modalImage && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.8)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => setIsImageModalOpen(false)}
          zIndex={2000}
        >
          <Image
            src={modalImage}
            alt="Full-size"
            maxH="90%"
            maxW="90%"
            objectFit="contain"
          />
        </Box>
      )}
    </Box>
  );
};

/* ----------------------------------------------------------------------------
   Profile Component
   This component renders your profile information along with your diagnosis
   details. A fixed chat balloon button at the bottom right opens the chat
   modal containing ChatModalContent.
------------------------------------------------------------------------------- */
function Profile() {
  const urlImageFile = "https://flaskapi.dent.lingosoft.co/show_img?img=";
  const [userRole, setUserRole] = useState();
  const [profileData, setProfileData] = useState();
  const [mainImages, setMainImages] = useState("mockup.png");
  const [smallImages, setSmallImages] = useState();
  const [diagnosisList, setDiagnosisList] = useState();
  const [selectedRow, setSelectedRow] = useState(0);
  const [diagnosisMainTime, setDiagnosisMainTime] = useState();
  const [imageResult, setImageResult] = useState();
  const [textareaNote, setTextareaNote] = useState("");
  const [nowDiagnosisId, setNowDiagnosisId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [checkedStates, setCheckedStates] = useState({});

  // useDisclosure for ImageUploadModal and Chat Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isChatModalOpen,
    onOpen: onChatModalOpen,
    onClose: onChatModalClose,
  } = useDisclosure();

  const auth = useAuth();
  const role = auth.user.role;
  const headers = { Authorization: auth.user.token };
  const toast = useToast();
  const profileId = useParams();

  useEffect(() => {
    fetchData(profileId.id);
    setUserRole(role);
  }, [role, profileId]);

  async function fetchData(profileId) {
    try {
      const response1 = axios.post(
        `${API_BASE_URL}/api/site/profile_detail`,
        { profileId },
        { headers }
      );
      const response2 = axios.post(
        `${API_BASE_URL}/api/site/diagnosis_list`,
        { profileId },
        { headers }
      );
      const [data1, data2] = await axios.all([response1, response2]);
      setProfileData(data1.data.results[0]);

      const dataForSetSwitch = data2.data.results;
      dataForSetSwitch.map((data, index) => {
        setCheckedStates((prevState) => ({
          ...prevState,
          [index]: data.diagnosis_status === "checked",
        }));
      });

      setDiagnosisList(dataForSetSwitch);
      displayDiagnosisDetail(dataForSetSwitch[0]);
      setIsVisible(true);
    } catch (error) {
      toast({
        title: "ไม่สามารถติดต่อ server ได้",
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
      console.error("Error making API calls:", error.message);
    }
  }

  async function displayDiagnosisDetail(diagnosisData) {
    try {
      if (diagnosisData?.id === nowDiagnosisId) return;
      setIsVisible(false);
      setNowDiagnosisId(diagnosisData.id);
      setTextareaNote(diagnosisData.note || "");
      setDiagnosisMainTime(
        converterToThaiDateAndTime(diagnosisData.created_datetime)
      );
      const response = await axios
        .post(
          `${API_BASE_URL}/api/site/diagnosis_detail`,
          { diagnosisId: diagnosisData.id },
          { headers }
        )
        .catch((error) => {
          toast({
            title: "ไม่สามารถติดต่อ server ได้",
            position: "top-right",
            status: "warning",
            isClosable: true,
          });
          console.error("Error making API calls:", error.message);
          throw error;
        });

      if (response.data.message === "success") {
        const results = response.data.results || [];
        setSmallImages(results);
        setMainImages(results[0]?.detected_filename || results[0]?.filename);
        setImageResult(results[0]?.ai_result?.[0] || "");
        setTimeout(() => {
          setIsVisible(true);
        }, 300);
      } else {
        toast({
          title: response.data.message,
          position: "top-right",
          status: "warning",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
    }
  }

  const displayDiagnosisImageDetail = (imageFileName, index) => {
    setMainImages(imageFileName);
    setImageResult(smallImages[index].ai_result?.[0]);
  };

  const handleTextareaChange = (event) => {
    setTextareaNote(event.target.value);
  };

  const handleButtonSaveNote = async () => {
    const response = await axios.post(
      `${API_BASE_URL}/api/site/diagnosis_note`,
      { nowDiagnosisId, textareaNote },
      { headers }
    );
    if (response.data.message === "success") {
      toast({
        title: "บันทึกเรียบร้อย",
        position: "top-right",
        status: "success",
        isClosable: true,
      });
      const index = diagnosisList.findIndex(
        (item) => item.id === nowDiagnosisId
      );
      diagnosisList[index].note = textareaNote;
    } else {
      toast({
        title: response.data.message,
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
    }
  };

  const updateDiagnosisStatus = async (diagnosisId, val) => {
    const response = await axios.post(
      `${API_BASE_URL}/api/site/diagnosis_update_diagnosis_status`,
      { diagnosisId, val },
      { headers }
    );
    if (response.data.message === "success") {
      toast({
        title: "บันทึกเรียบร้อย",
        position: "top-right",
        status: "success",
        isClosable: true,
      });
    } else {
      toast({
        title: response.data.message,
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
    }
  };

  const [isZoomed, setIsZoomed] = useState(false);
  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const [displayCount, setDisplayCount] = useState(5);
  const [deleteDiagnosisId, setDeleteDiagnosisId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDelete = (userId) => {
    setDeleteDiagnosisId(userId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/site/diagnosis_delete`,
        { deleteDiagnosisId },
        { headers }
      );
      if (response.data.message === "success") {
        setDeleteDiagnosisId(null);
        setIsDeleteModalOpen(false);
        setDiagnosisList((diagnosisList) => {
          const filteredList = diagnosisList.filter(
            (item) => item.id !== deleteDiagnosisId
          );
          if (filteredList.length !== 0) {
            displayDiagnosisDetail(filteredList[0]);
            setSelectedRow(0);
          } else {
            setSmallImages("");
            setMainImages("mockup.png");
            setImageResult("");
            setTextareaNote("");
            setDiagnosisMainTime("");
            setNowDiagnosisId("");
          }
          return filteredList;
        });
      } else {
        toast({
          title: response.data.message,
          position: "top-right",
          status: "warning",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "ไม่สามารถติดต่อ server ได้",
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
      console.error("Error making API calls:", error.message);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const onRefresh = () => {
    fetchData(profileId.id);
  };

  return (
    <>
      <Box p={4}>
        <Card mt={2} p={5}>
          <Flex>
            <Heading as="h4" size="md" py={2}>
              ข้อมูลผู้รับบริการ
            </Heading>
            <Spacer />
            {!isVisible ? (
              <Spinner
                thickness="5px"
                speed="0.75s"
                emptyColor="gray.200"
                color="green.600"
                size="lg"
              />
            ) : null}
          </Flex>

          <Divider my="2" />
          <Grid
            templateColumns={{ base: "100%", md: "15vw 1fr 15vw 1fr" }}
            mt={2}
          >
            <GridItem bg={"gray.200"} p={2}>
              <Box display="flex" alignItems="center">
                <Icon as={FaIdCard} mr="2" />
                <Text>ชื่อ-สกุล:</Text>
              </Box>
            </GridItem>
            <GridItem p={2} border="0.5px solid #eaeaea">
              <Text>
                {profileData?.first_name} {profileData?.last_name}
              </Text>
            </GridItem>
            <GridItem bg={"gray.200"} p={2}>
              <Box display="flex" alignItems="center">
                <Icon as={FaBirthdayCake} mr="2" />
                <Text>วันเกิด:</Text>
              </Box>
            </GridItem>
            <GridItem p={2} border="0.5px solid #eaeaea">
              {profileData?.birthday && (
                <Text>
                  {converterToThaiDate(profileData?.birthday)} อายุ{" "}
                  {converterToAge(profileData.birthday)} ปี
                </Text>
              )}
            </GridItem>
            <GridItem bg={"gray.200"} p={2}>
              <Box display="flex" alignItems="center">
                <Icon as={FaPhone} mr="2" />
                <Text>เบอร์โทรผู้รับบริการ:</Text>
              </Box>
            </GridItem>
            <GridItem p={2} border="0.5px solid #eaeaea">
              <Text>{profileData?.phone}</Text>
            </GridItem>
            <GridItem bg={"gray.200"} p={2}>
              <Box display="flex" alignItems="center">
                <Icon as={FaHospital} mr="2" />
                <Text>สถานพยาบาลต้นสังกัด:</Text>
              </Box>
            </GridItem>
            <GridItem p={2} border="0.5px solid #eaeaea">
              <Text>{profileData?.hospital_name}</Text>
            </GridItem>
            <GridItem bg={"gray.200"} p={2}>
              <Box display="flex" alignItems="center">
                <Icon as={FaMapMarker} mr="2" />
                <Text>ที่อยู่ผู้รับบริการ:</Text>
              </Box>
            </GridItem>
            <GridItem p={2} border="0.5px solid #eaeaea">
              <Text>{profileData?.address}</Text>
            </GridItem>
            <GridItem bg={"gray.200"} p={2}>
              <Box display="flex" alignItems="flex-start">
                <Box mr="2">
                  <Icon as={FaClipboard} />
                </Box>
                <Text>ต้นสังกัด/หน่วยงาน:</Text>
              </Box>
            </GridItem>
            <GridItem p={2} border="0.5px solid #eaeaea">
              <Text>{profileData?.organization}</Text>
            </GridItem>
          </Grid>
        </Card>
        <Card mt={4} p={5} pt={3}>
          {diagnosisMainTime}
          <Grid templateColumns={{ base: "1fr", md: "4fr 5fr" }} gap={2}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: isVisible ? 1 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <Flex direction={"column"}>
                <Box position="relative">
                  {!mainImages.includes("_output") && (
                    <Text color={"white"} position="absolute" left={5} top={1}>
                      อยู่ระหว่างประมวลผล...
                    </Text>
                  )}
                  <Image
                    rounded={"lg"}
                    height={"auto"}
                    width={"100%"}
                    src={`${urlImageFile}${mainImages}`}
                    alt="#"
                  />
                  <Button
                    size={"xs"}
                    position="absolute"
                    top={1}
                    right={0}
                    m={1}
                    p={1}
                    colorScheme="white"
                    onClick={toggleZoom}
                  >
                    <MdZoomOutMap size={20} />
                  </Button>
                  {isZoomed && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        zIndex: 9999,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={toggleZoom}
                    >
                      <img
                        src={`${urlImageFile}${mainImages}`}
                        alt="Zoomed Image"
                        style={{
                          maxWidth: "90%",
                          maxHeight: "90%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </Box>
                <Flex pt={1}>
                  <HStack spacing={1} direction="row">
                    {smallImages && smallImages.length > 0
                      ? smallImages.map((data, index) => (
                          <Flex
                            key={index}
                            direction="column"
                            alignItems="center"
                          >
                            <Image
                              boxSize="40px"
                              minW={"50px"}
                              objectFit="cover"
                              src={`${urlImageFile}${
                                data.detected_filename || data.filename
                              }`}
                              alt="Small Image"
                              cursor="pointer"
                              rounded={"md"}
                              onClick={() =>
                                displayDiagnosisImageDetail(
                                  data.detected_filename || data.filename,
                                  index
                                )
                              }
                            />
                            <Text mt={0}>{data.view_type}</Text>
                          </Flex>
                        ))
                      : ""}
                  </HStack>
                </Flex>
                <Box
                  mt={4}
                  bgColor={"gray.100"}
                  p="2"
                  minHeight="60px"
                  borderRadius="5"
                >
                  <Heading size="sm" textTransform="uppercase">
                    ลักษณะที่ระบบตรวจจับได้
                  </Heading>
                  <Text px="2" pt="1" fontSize="sm">
                    {imageResult && imageResult.length > 0
                      ? imageResult.map((data, index) => (
                          <Badge
                            key={index}
                            colorScheme={
                              data.detected_type === "Metal" ? "blue" : "green"
                            }
                            mr="2"
                          >
                            {data.detected_type}
                          </Badge>
                        ))
                      : ""}
                  </Text>
                </Box>
                <Box mt={4} bgColor={"gray.100"} p="2" borderRadius="5">
                  <Heading size="sm" textTransform="uppercase">
                    บันทึกการตรวจ
                  </Heading>
                  {userRole !== "doctor" ? (
                    <Box px="2">{textareaNote}</Box>
                  ) : (
                    <>
                      <Textarea
                        placeholder="..."
                        minHeight="140px"
                        border="none"
                        value={textareaNote}
                        onChange={handleTextareaChange}
                        color={"gray.600"}
                      />
                      <Flex justify="flex-end" mt="1">
                        {textareaNote !== "" ? (
                          <Button
                            size={"xs"}
                            colorScheme={"green"}
                            onClick={handleButtonSaveNote}
                          >
                            บันทึก
                          </Button>
                        ) : (
                          ""
                        )}
                      </Flex>
                    </>
                  )}
                </Box>
              </Flex>
            </motion.div>

            <TableContainer>
              <Table variant="" colorScheme="gray">
                <TableCaption
                  placement="top"
                  fontWeight="bold"
                  bg={"#abcbbe"}
                  py={3}
                  mb={3}
                  mt={0}
                >
                  <Flex justify="space-between">
                    <Text>ประวัติการตรวจ</Text>
                    <Button
                      variant={"outline"}
                      colorScheme={"green"}
                      size="md"
                      onClick={onOpen}
                    >
                      เพิ่มรายการ
                    </Button>
                  </Flex>
                </TableCaption>
                <Thead>
                  <Tr borderBottom={"1px solid #eaeaea"}>
                    <Th>วันที่ทำการบันทึกข้อมูล</Th>
                    <Th textAlign={"center"}>จำนวนจุดต้องสงสัย</Th>
                    <Th textAlign={"center"}>จำนวนภาพ</Th>
                    <Th textAlign={"center"}>สถานะการตรวจ</Th>
                    {userRole !== "doctor" ? (
                      <Th textAlign={"center"}>ลบ</Th>
                    ) : (
                      ""
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {diagnosisList?.slice(0, displayCount).map((data, index) => (
                    <Tr
                      key={index}
                      _hover={{ cursor: "pointer", bg: "gray.200" }}
                      bg={selectedRow === index ? "gray.200" : ""}
                      color={selectedRow === index ? "gray.500" : "gray.600"}
                      onClick={() => {
                        setSelectedRow(index);
                        displayDiagnosisDetail(data);
                      }}
                    >
                      <Td>
                        {converterToThaiDateAndTime(data.created_datetime)}
                      </Td>
                      <Td textAlign={"center"}>{data.suspicious_point}</Td>
                      <Td textAlign={"center"}>{data.count_image}</Td>
                      <Td textAlign={"center"}>
                        {userRole !== "doctor" ? (
                          <Box>
                            {data.diagnosis_status === "checked"
                              ? "ตรวจแล้ว"
                              : "รอตรวจ"}
                          </Box>
                        ) : (
                          <Switch
                            size="md"
                            isChecked={checkedStates[index]}
                            onChange={(e) => {
                              setCheckedStates((prevState) => ({
                                ...prevState,
                                [index]: e.target.checked,
                              }));
                              updateDiagnosisStatus(data.id, e.target.checked);
                            }}
                          />
                        )}
                      </Td>
                      {userRole !== "doctor" ? (
                        <Td textAlign={"center"}>
                          <HStack spacing={2}>
                            <button onClick={() => handleDelete(data.id)}>
                              <DeleteIcon boxSize={5} color="red.500" />
                            </button>
                          </HStack>
                        </Td>
                      ) : (
                        ""
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {displayCount < diagnosisList?.length && (
                <Center>
                  <Button
                    onClick={() =>
                      setDisplayCount((prevCount) => prevCount + 5)
                    }
                    variant="outline"
                    colorScheme="blue"
                    size="sm"
                    mt={4}
                  >
                    เพิ่มเติม
                  </Button>
                </Center>
              )}
            </TableContainer>
          </Grid>
        </Card>
      </Box>

      {/* Fixed Chat Balloon Button */}
      <Box position="fixed" bottom="20px" right="20px" zIndex="1000">
        <Button
          borderRadius="full"
          colorScheme="teal"
          size="lg"
          onClick={onChatModalOpen}
        >
          <Icon as={FaComments} boxSize={6} />
        </Button>
      </Box>

      {/* Chat Modal */}
      <Modal
        isOpen={isChatModalOpen}
        onClose={onChatModalClose}
        size="3xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <ChatModalContent />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"red.500"}>ลบข้อมูล</ModalHeader>
          <ModalCloseButton />
          <ModalBody>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDeleteConfirm}>
              ลบ
            </Button>
            <Button onClick={handleDeleteCancel} ml={2}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {profileId && (
        <ImageUploadModal
          isOpen={isOpen}
          onClose={onClose}
          onRefresh={onRefresh}
          patientId={profileId.id}
        />
      )}
    </>
  );
}

export default Profile;
