import React from "react";
import Chart from "react-apexcharts";
import { Box, Text } from "@chakra-ui/react";

const UserChart = () => {
	// Define your user data
	const userData = {
		labels: ["ครู", "เจ้าหน้าที่ อ.ส.ม", "ทันตแพทย์", "ผู้ใช้งานทั่วไป"],
		series: [10, 20, 15, 30], // Replace with your actual user numbers
	};

	// ApexCharts options
	const chartOptions = {
		chart: {
			type: "donut",
		},
		labels: userData.labels,
	};

	return (
		<Box w={"100%"} bgColor={"white"} p={5} mt={5}>
			<Text fontSize="xl" mb={2}>
				กราฟแสดงจำนวนผู้ใช้งาน
			</Text>
			<Box width="350px" justifyItems={"center"}>
				<Chart
					options={chartOptions}
					series={userData.series}
					type="donut"
					height={350}
				/>
			</Box>
		</Box>
	);
};

export default UserChart;
