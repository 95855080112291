import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";

/**
 * A small palette of colors to use for each unique class.
 * You can expand this if you expect more classes.
 */
const COLOR_PALETTE = [
  "red",
  "green",
  "blue",
  "orange",
  "purple",
  "magenta",
  "cyan",
  "brown",
];

const ImageDetectionComponent = ({ apiUrl, title }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [detectionResults, setDetectionResults] = useState(null);

  // Holds the class -> color mapping, e.g. { caries: "red", calculus: "blue" }
  const [classColors, setClassColors] = useState({});

  // Holds the class -> visibility mapping, e.g. { caries: true, calculus: false }
  const [visibleClasses, setVisibleClasses] = useState({});

  // Holds the number of bounding boxes for each class, e.g. { caries: 2, calculus: 1 }
  const [classCounts, setClassCounts] = useState({});

  // Loading & error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // References for the file input and canvas
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);

  /**
   * Handle file selection
   */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setDetectionResults(null); // Clear old detections
    setError(null);
  };

  /**
   * Handle upload & detection
   */
  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    setIsLoading(true);
    setError(null);

    try {
      // Call /detect_all, which returns JSON like:
      // {
      //   "detections": {
      //     "calculus": [...],
      //     "carries": [...],
      //     "gum": [...],
      //     ...
      //   }
      // }
      const response = await axios.post(apiUrl, formData);
      const detections = response.data.detections || {};
      setDetectionResults(detections);

      // Collect all unique classes
      const foundClasses = new Set();
      Object.keys(detections).forEach((modelName) => {
        detections[modelName].forEach((det) => {
          foundClasses.add(det.class_name);
        });
      });

      // Update classColors for new classes
      setClassColors((prevColors) => {
        const updatedColors = { ...prevColors };
        let colorIndex = Object.keys(updatedColors).length;
        foundClasses.forEach((cls) => {
          if (!updatedColors[cls]) {
            // Assign a color from the palette
            const color =
              COLOR_PALETTE[colorIndex % COLOR_PALETTE.length] || "red";
            updatedColors[cls] = color;
            colorIndex++;
          }
        });
        return updatedColors;
      });

      // Update visibleClasses to ensure new classes default to visible
      setVisibleClasses((prevVisible) => {
        const updatedVisible = { ...prevVisible };
        let changed = false;
        foundClasses.forEach((cls) => {
          if (!(cls in updatedVisible)) {
            updatedVisible[cls] = true; // default to visible
            changed = true;
          }
        });
        return changed ? updatedVisible : prevVisible;
      });
    } catch (err) {
      setError(
        err.response?.data?.error ||
          "An error occurred while uploading the image."
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Re-draw the canvas whenever detectionResults, visibleClasses,
   * or the selected file changes. Also compute class counts.
   */
  useEffect(() => {
    if (!detectionResults || !selectedFile) return;

    // 1) Compute how many bounding boxes exist for each class
    const newCounts = {};
    Object.keys(detectionResults).forEach((modelName) => {
      detectionResults[modelName].forEach((det) => {
        const cls = det.class_name;
        newCounts[cls] = (newCounts[cls] || 0) + 1;
      });
    });
    setClassCounts(newCounts);

    // 2) Draw bounding boxes
    drawDetections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectionResults, visibleClasses, selectedFile]);

  /**
   * Draw bounding boxes on the canvas for classes that are currently visible
   */
  const drawDetections = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    // Create an image element to load the original file
    const img = new Image();
    img.onload = function () {
      // Resize canvas to match image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the original image
      ctx.drawImage(img, 0, 0);

      // Determine font size based on image dimension
      const fontSize = Math.max(12, Math.min(img.width, img.height) / 50);
      ctx.font = `${fontSize}px Arial`;
      ctx.lineWidth = 2;

      // Iterate over each model's detections
      Object.keys(detectionResults).forEach((modelName) => {
        detectionResults[modelName].forEach((det) => {
          const [x1, y1, x2, y2] = det.box;
          const cls = det.class_name;

          // Only draw if the class is visible
          if (!visibleClasses[cls]) return;

          // Set color for this class
          const color = classColors[cls] || "red";
          ctx.strokeStyle = color;

          // Draw bounding box
          ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

          // Draw label background
          const label = cls;
          const textWidth = ctx.measureText(label).width;
          const padding = 4;
          ctx.fillStyle = color;
          ctx.fillRect(
            x1,
            y1 - fontSize - padding,
            textWidth + padding * 2,
            fontSize + padding
          );

          // Draw label text in white
          ctx.fillStyle = "white";
          ctx.fillText(label, x1 + padding, y1 - padding);
        });
      });
    };
    // Convert selected file to object URL
    img.src = URL.createObjectURL(selectedFile);
  };

  /**
   * Toggle the visibility of a class
   */
  const toggleClassVisibility = (cls) => {
    setVisibleClasses((prev) => ({
      ...prev,
      [cls]: !prev[cls],
    }));
  };

  /**
   * Clear everything
   */
  const handleClear = () => {
    setSelectedFile(null);
    setDetectionResults(null);
    setError(null);
    setIsLoading(false);
    setClassColors({});
    setVisibleClasses({});
    setClassCounts({});

    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    // Clear canvas
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  return (
    <VStack spacing={6} align="center" w="full" py={8} px={4}>
      {/* Header */}
      <Box textAlign="center">
        <Text fontSize="2xl" fontWeight="bold" mb={2} color="teal.600">
          {title}
        </Text>
        <Text fontSize="md" color="gray.500">
          Upload an image and let the system detect.
        </Text>
      </Box>

      {/* File Input */}
      <Input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        disabled={isLoading}
        variant="filled"
        focusBorderColor="teal.400"
      />

      {/* Error Alert */}
      {error && (
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      )}

      {/* Buttons */}
      <HStack spacing={4}>
        <Button
          colorScheme="teal"
          onClick={handleUpload}
          isLoading={isLoading}
          loadingText="Processing..."
          isDisabled={!selectedFile || isLoading}
          size="lg"
          shadow="md"
        >
          Upload and Detect
        </Button>
        <Button
          colorScheme="orange"
          onClick={handleClear}
          isDisabled={isLoading}
          size="lg"
          shadow="md"
        >
          Clear
        </Button>
      </HStack>

      {/* Loading Spinner */}
      {isLoading && <Spinner size="xl" thickness="4px" color="teal.500" />}

      {/* Canvas + Filter Panel */}
      {selectedFile && (
        <Flex
          mt={6}
          justify="center"
          align="start"
          gap={6}
          direction={["column", "row"]}
          w="full"
        >
          {/* Canvas for image + bounding boxes */}
          {detectionResults && <Box flex="1" textAlign="center">
            <Text fontSize="lg" fontWeight="bold" color="teal.600" mb={4}>
              Detection Results
            </Text>
            <canvas
              ref={canvasRef}
              style={{ border: "1px solid #ccc", maxWidth: "100%" }}
            />
          </Box>}

          {/* Class Filter Panel */}
          {Object.keys(visibleClasses).length > 0 && (
            <Box flex="0.3" p={4} border="1px solid #ddd" borderRadius="md">
              <Text fontSize="lg" mb={2} fontWeight="bold">
                ตัวกรอง
              </Text>
              {Object.keys(visibleClasses).map((cls) => (
                <HStack key={cls} mb={2}>
                  <Checkbox
                    isChecked={visibleClasses[cls]}
                    onChange={() => toggleClassVisibility(cls)}
                    colorScheme="teal"
                  >
                    {/* Show class name and the count of detections for that class */}
                    <Text fontWeight="semibold" color={classColors[cls]}>
                      {cls} ({classCounts[cls] || 0})
                    </Text>
                  </Checkbox>
                </HStack>
              ))}
            </Box>
          )}
        </Flex>
      )}
    </VStack>
  );
};

export default ImageDetectionComponent;
