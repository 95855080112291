import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  Input,
  Button,
  VStack,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Image
} from '@chakra-ui/react';
import { ChatList as ChatListComponent } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { FiExternalLink, FiImage, FiSend } from 'react-icons/fi';

// React Big Calendar and moment imports
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

// Import custom components
import TimeRangeWidget from '../widget/TimeRangeWidget';
import DatePickerLabel from '../widget/DatePickerLabel'; // Adjust path as needed
import { ChatIcon, LinkIcon } from '@chakra-ui/icons';

function ChatList() {
  // Set up localizer for react-big-calendar
  const localizer = momentLocalizer(moment);
  const toast = useToast();

  // ------------------------------
  // Helper to validate URL
  // ------------------------------
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  // Helper to convert URLs in text to clickable links.
  const linkifyText = (text) => {
    const urlRegex = /(https?:\/\/\S+)/g;
    return text.split(urlRegex).map((part, i) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={i}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      }
      return <span key={i}>{part}</span>;
    });
  };

  // Helper to get the image source.
  const getImageSrc = (imageData) => {
    if (imageData instanceof Blob) {
      return URL.createObjectURL(imageData);
    }
    return imageData;
  };

  // ------------------------------
  // Calendar Events State
  // ------------------------------
  const [calendarEvents, setCalendarEvents] = useState([
    {
      id: 1,
      title: 'Team Meeting',
      start: new Date(2025, 1, 20, 10, 0),
      end: new Date(2025, 1, 20, 11, 0),
      meetingLink: ''
    },
    {
      id: 2,
      title: 'Client Lunch',
      start: new Date(2025, 1, 21, 12, 0),
      end: new Date(2025, 1, 21, 13, 0),
      meetingLink: ''
    },
  ]);

  // ------------------------------
  // Agenda modal state (for calendar events add/edit/delete)
  // ------------------------------
  const [isAgendaModalOpen, setIsAgendaModalOpen] = useState(false);
  const [agendaData, setAgendaData] = useState(null);
  const [createAgendaLink, setCreateAgendaLink] = useState("no");

  // ------------------------------
  // Chat State
  // ------------------------------
  const initialConversations = {
    1: [
      {
        position: 'left',
        type: 'text',
        text: 'สวัสดีครับ จากจักรกฤษณ์!',
        date: new Date(),
      },
      {
        position: 'right',
        type: 'text',
        text: 'สวัสดีครับ ยินดีต้อนรับ!',
        date: new Date(),
      },
    ],
    2: [
      {
        position: 'left',
        type: 'text',
        text: 'สวัสดีครับ จากเอกภพ!',
        date: new Date(),
      },
      {
        position: 'left',
        type: 'image',
        image: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Dental_Caries_Cavity_2.JPG',
        date: new Date(),
      },
      {
        position: 'left',
        type: 'text',
        text: 'อยากนัดตรวจฟันครับ?',
        date: new Date(),
      }
    ],
    3: [
      {
        position: 'left',
        type: 'text',
        text: 'สวัสดีครับ จากวิลาวัลย์!',
        date: new Date(),
      },
      {
        position: 'left',
        type: 'text',
        text: 'ขออนุญาตปรึกษาเรื่องฟันนะคะ',
        date: new Date(),
      },
    ],
  };

  const [activeChat, setActiveChat] = useState(null);
  const [conversations, setConversations] = useState(initialConversations);
  const [inputValue, setInputValue] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const fileInputRef = useRef(null);

  // ------------------------------
  // Meeting Link Modal State (from Chat Header)
  // ------------------------------
  const [isMeetingLinkModalOpen, setIsMeetingLinkModalOpen] = useState(false);
  const [meetingType, setMeetingType] = useState("now"); // "now" or "later"
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingDate, setMeetingDate] = useState(""); // "YYYY-MM-DD" (set via DatePickerLabel)
  const [meetingStartTime, setMeetingStartTime] = useState(""); // e.g., "09:00"
  const [meetingEndTime, setMeetingEndTime] = useState("");     // e.g., "10:00"
  const [meetingLink, setMeetingLink] = useState("");

  // ------------------------------
  // Dummy Participants Data
  // ------------------------------
  const participants = [
    {
      id: 1,
      avatar: 'https://picsum.photos/seed/picsum/140/140',
      title: 'จักรกฤษณ์',
      subtitle: 'สวัสดีครับ',
      date: new Date(),
      unread: 0,
    },
    {
      id: 2,
      avatar: 'https://picsum.photos/seed/picsum/140/140',
      title: 'เอกภพ',
      subtitle: 'อยากนัดตรวจฟันครับ?',
      date: new Date(),
      unread: 3,
    },
    {
      id: 3,
      avatar: 'https://picsum.photos/seed/picsum/140/140',
      title: 'วิลาวัลย์',
      subtitle: 'ขออนุญาตปรึกษาเรื่องฟันนะคะ',
      date: new Date(),
      unread: 2,
    }
  ];

  // ------------------------------
  // Highlighting Selected Participant
  // ------------------------------
  // Create a modified participants list that adds a "className" for the active chat.
  const modifiedParticipants = participants.map((p) => ({
    ...p,
    className: activeChat && activeChat.id === p.id ? 'active-chat' : ''
  }));

  // ------------------------------
  // Chat Handlers
  // ------------------------------
  const handleChatItemClick = (item) => {
    setActiveChat(item);
    setConversations((prev) => {
      if (prev[item.id]) return prev;
      return { ...prev, [item.id]: [] };
    });
  };

  const handleSendMessage = () => {
    if (!inputValue.trim() || !activeChat) return;
    const newMessage = {
      position: 'right',
      type: 'text',
      text: inputValue,
      date: new Date(),
    };
    setConversations((prev) => ({
      ...prev,
      [activeChat.id]: [...(prev[activeChat.id] || []), newMessage],
    }));
    setInputValue('');
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    if (!activeChat) return;
    const file = e.target.files[0];
    if (file) {
      // Store the Blob directly.
      const newImageMessage = {
        position: 'right',
        type: 'image',
        image: file,
        date: new Date(),
      };
      setConversations((prev) => ({
        ...prev,
        [activeChat.id]: [...(prev[activeChat.id] || []), newImageMessage],
      }));
      e.target.value = null;
    }
  };

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const messages = activeChat ? conversations[activeChat.id] || [] : [];

  // ------------------------------
  // Calendar Agenda Handlers
  // ------------------------------
  const generateRandomString = (length) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const generateMeetingLink = () => {
    return `https://vroom.truevirtualworld.com/meeting-${generateRandomString(9)}`;
  };

  const handleSelectSlot = (slotInfo) => {
    setAgendaData({
      id: null,
      title: '',
      start: slotInfo.start,
      end: slotInfo.end,
      meetingLink: generateMeetingLink(),
    });
    setCreateAgendaLink("no");
    setIsAgendaModalOpen(true);
  };

  const handleSelectEvent = (event) => {
    console.log("Event selected:", event);
    setAgendaData(event);
    setCreateAgendaLink(event.meetingLink ? "yes" : "no");
    setIsAgendaModalOpen(true);
  };

  const handleSaveAgenda = () => {
    if (!agendaData.title) return;
    if (createAgendaLink === "no") {
      agendaData.meetingLink = "";
    } else if (createAgendaLink === "yes" && !agendaData.meetingLink) {
      agendaData.meetingLink = generateMeetingLink();
    }
    if (agendaData.id) {
      setCalendarEvents((prev) =>
        prev.map((ev) => (ev.id === agendaData.id ? agendaData : ev))
      );
    } else {
      setCalendarEvents((prev) => [
        ...prev,
        { ...agendaData, id: Date.now() },
      ]);
    }
    setIsAgendaModalOpen(false);
  };

  const handleDeleteAgenda = () => {
    if (agendaData && agendaData.id) {
      setCalendarEvents((prev) =>
        prev.filter((ev) => ev.id !== agendaData.id)
      );
      setIsAgendaModalOpen(false);
    }
  };

  // Handler for changing the "create meeting link" option in the agenda modal.
  const handleCreateAgendaLinkChange = (value) => {
    setCreateAgendaLink(value);
    if (value === "yes") {
      setAgendaData((prev) => ({
        ...prev,
        meetingLink: generateMeetingLink(),
      }));
    } else {
      setAgendaData((prev) => ({
        ...prev,
        meetingLink: "",
      }));
    }
  };

  // ------------------------------
  // Meeting Link Modal Handlers (from Chat Header)
  // ------------------------------
  const openMeetingModal = () => {
    setMeetingType("now");
    setMeetingTitle("");
    setMeetingDate("");
    setMeetingStartTime("");
    setMeetingEndTime("");
    setMeetingLink(generateMeetingLink());
    setIsMeetingLinkModalOpen(true);
  };

  const handleMeetingSubmit = () => {
    if (!activeChat) return;
    let messageText = "";
    if (meetingType === "now") {
      messageText = `เริ่มประชุมตอนนี้ (คลิ๊กที่ลิ้งค์เพื่อเข้าประชุม): ${meetingLink}`;
    } else {
      if (!meetingTitle || !meetingDate || !meetingStartTime || !meetingEndTime) {
        toast({
          title: "Missing input",
          description: "กรุณาระบุหัวข้อประชุม, วันที่และเวลาประชุม",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const meetingStart = new Date(`${meetingDate}T${meetingStartTime}`);
      const meetingEnd = new Date(`${meetingDate}T${meetingEndTime}`);
      messageText = `Scheduled meeting: ${meetingTitle}\nDate/Time: ${moment(meetingStart).format('LLL')} - ${moment(meetingEnd).format('LLL')}\nMeeting Link: ${meetingLink}`;
      setCalendarEvents((prev) => [
        ...prev,
        { id: Date.now(), title: meetingTitle, start: meetingStart, end: meetingEnd, meetingLink },
      ]);
    }
    const newMeetingMessage = {
      position: 'right',
      type: 'text',
      text: messageText,
      date: new Date(),
    };
    setConversations((prev) => ({
      ...prev,
      [activeChat.id]: [...(prev[activeChat.id] || []), newMeetingMessage],
    }));
    setIsMeetingLinkModalOpen(false);
  };

  return (
    <Flex h="80vh">
      {/* Global styles to highlight the active chat list item */}
      <style jsx global>{`
        .active-chat {
          background-color: #e0f7fa !important;
        }
      `}</style>

      {/* Left Panel: Participants and Calendar */}
      <Box w={['100%', '50%']} p={4} borderWidth="1px" borderColor="black" pr={4} mr={2}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          รายชื่อผู้รับบริการ
        </Text>
        <ChatListComponent
          dataSource={modifiedParticipants}
          onClick={handleChatItemClick}
        />
        <Box bgColor="white" p={4} mt={4}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            ปฏิทิน
          </Text>
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            style={{ height: 400 }}
          />
        </Box>
      </Box>

      {/* Right Panel: Chat Area */}
      <Box
        backgroundColor="blue.50"
        borderColor="black"
        borderWidth="1px"
        flex="1"
        display="flex"
        flexDirection="column"
      >
        {activeChat ? (
          <>
            {/* Chat Header with Meeting Link Button */}
            <Box backgroundColor="white" borderBottom="1px solid #e2e8f0" pb={2} mb={4} px={4}>
              <Flex alignItems="center" justifyContent="space-between">
                <Text fontSize="2xl" fontWeight="bold">
                  {activeChat.title}
                </Text>
                <Button mt={2} size="sm" variant="outline" leftIcon={<LinkIcon />} colorScheme="teal" onClick={openMeetingModal}>
                  สร้างลิงค์ประชุม
                </Button>
              </Flex>
            </Box>

            {/* Messages Display */}
            <Box flex="1" overflowY="auto" mb={4}>
              <VStack spacing={4} align="stretch">
                {messages.map((msg, index) => (
                  <Box
                    key={index}
                    alignSelf={msg.position === 'right' ? 'flex-end' : 'flex-start'}
                    maxW="70%"
                    bg={msg.position === 'right' ? 'blue.100' : 'gray.100'}
                    p={3}
                    borderRadius="md"
                  >
                    {msg.type === 'text' && (
                      <Text>
                        {linkifyText(msg.text)}
                      </Text>
                    )}
                    {msg.type === 'image' && (
                      <Image
                        src={getImageSrc(msg.image)}
                        alt="Sent image"
                        maxH="300px"
                        objectFit="cover"
                        borderRadius="md"
                        cursor="pointer"
                        onClick={() => handleImageClick(getImageSrc(msg.image))}
                      />
                    )}
                    <Text fontSize="xs" color="gray.500" textAlign="right">
                      {msg.date.toLocaleTimeString()}
                    </Text>
                  </Box>
                ))}
              </VStack>
            </Box>

            {/* Message Input */}
            <Flex
              bgColor="gray.200"
              my={1}
              mx={1}
              as="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSendMessage();
              }}
            >
              <Input
                bgColor="white"
                _placeholder={{ color: 'gray.300' }}
                placeholder="พิมพ์ข้อความ..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                mr={2}
              />
              <Button leftIcon={<FiSend />} type="submit" colorScheme="blue" mr={2}>
                ส่ง
              </Button>
              <Button leftIcon={<FiImage />} variant="outline" onClick={handleUploadClick} colorScheme="green">
                ภาพ
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
            </Flex>
          </>
        ) : (
          <Flex align="center" justify="center" h="100%">
            <Text fontSize="lg" color="gray.500">
              Select a participant to start chatting
            </Text>
          </Flex>
        )}
      </Box>

      {/* Modal for Full-size Image Preview */}
      <Modal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        size="5xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalImage && (
              <Image src={modalImage} alt="Full-size image" width="100%" />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal for Creating/Scheduling Meeting Link (from Chat Header) */}
      <Modal
        size="2xl"
        isOpen={isMeetingLinkModalOpen}
        onClose={() => setIsMeetingLinkModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>สร้างลิงค์ประชุม</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <RadioGroup value={meetingType} onChange={setMeetingType}>
                <Stack direction="row">
                  <Radio value="now">เริ่มประชุมตอนนี้</Radio>
                  <Radio value="later">ประชุมภายหลัง</Radio>
                </Stack>
              </RadioGroup>
              {meetingType === "later" && (
                <>
                  <Input
                    placeholder="หัวข้อประชุม..."
                    value={meetingTitle}
                    onChange={(e) => setMeetingTitle(e.target.value)}
                  />
                  <DatePickerLabel initDateStr={meetingDate} setSelectedDay={setMeetingDate} />
                  <Text>ช่วงเวลา:</Text>
                  <TimeRangeWidget
                    resetTime={false}
                    sHour={""}
                    sMinute={""}
                    eHour={""}
                    eMinute={""}
                    setStartTime={setMeetingStartTime}
                    setEndTime={setMeetingEndTime}
                  />
                </>
              )}
              <Input placeholder="Meeting Link" value={meetingLink} isReadOnly />
              <Button colorScheme="blue" onClick={handleMeetingSubmit}>
                ส่งลิงค์ประชุม
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal for Adding/Editing/Deleting Calendar Agenda */}
      <Modal
        size="2xl"
        isOpen={isAgendaModalOpen}
        onClose={() => setIsAgendaModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {agendaData && agendaData.id ? 'แก้ไขนัดหมาย' : 'เพิ่มนัดหมาย'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="หัวข้อประชุม..."
                value={agendaData ? agendaData.title : ''}
                onChange={(e) =>
                  setAgendaData({ ...agendaData, title: e.target.value })
                }
              />
              <RadioGroup value={createAgendaLink} onChange={handleCreateAgendaLinkChange}>
                <Stack direction="row">
                  <Radio value="yes">สร้างลิงค์ประชุม</Radio>
                  <Radio value="no">ไม่สร้างลิงค์ประชุม</Radio>
                </Stack>
              </RadioGroup>
              {createAgendaLink === "yes" && (
                <HStack spacing={2} w="100%">
                  <Input
                    placeholder="Meeting Link"
                    value={agendaData && agendaData.meetingLink ? agendaData.meetingLink : ''}
                    onChange={(e) =>
                      setAgendaData({ ...agendaData, meetingLink: e.target.value })
                    }
                  />
                  {agendaData &&
                    agendaData.meetingLink &&
                    isValidUrl(agendaData.meetingLink) && (
                      <Button
                        leftIcon={<FiExternalLink />}
                        variant="outline"
                        colorScheme="blue"
                        onClick={() =>
                          window.open(agendaData.meetingLink, '_blank')
                        }
                      >
                        เปิดลิงค์
                      </Button>
                    )}
                </HStack>
              )}
              <Input
                type="datetime-local"
                placeholder="Start Time"
                value={
                  agendaData
                    ? moment(agendaData.start).format('YYYY-MM-DDTHH:mm')
                    : ''
                }
                onChange={(e) => {
                  const newStart = new Date(e.target.value);
                  setAgendaData({ ...agendaData, start: newStart });
                }}
              />
              <Input
                type="datetime-local"
                placeholder="End Time"
                value={
                  agendaData
                    ? moment(agendaData.end).format('YYYY-MM-DDTHH:mm')
                    : ''
                }
                onChange={(e) => {
                  const newEnd = new Date(e.target.value);
                  setAgendaData({ ...agendaData, end: newEnd });
                }}
              />
              <Flex w="100%" justifyContent="space-between">
                <Flex>
                  <Button mr={2} colorScheme="blue" onClick={handleSaveAgenda}>
                    บันทึก
                  </Button>
                  <Button colorScheme="gray" onClick={() => setIsAgendaModalOpen(false)}>
                    ยกเลิก
                  </Button>
                </Flex>
                <Flex>
                  {agendaData && agendaData.id && (
                    <Button colorScheme="red" onClick={handleDeleteAgenda}>
                      ลบ
                    </Button>
                  )}
                </Flex>
              </Flex>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default ChatList;
